@import 'elements/variables';
@import 'elements/mediaPrint';
@import 'elements/font';
@import 'elements/loader';
@import 'elements/logoCompany';
@import 'elements/bgBubble';
@import 'elements/login';
@import 'elements/form';
@import 'elements/mentionCorporate';
@import 'elements/sidebar';
@import 'elements/button';
@import 'elements/news';
@import 'elements/demandes';
@import 'elements/infoPerso';
@import 'elements/_accueil';
@import 'elements/_gestionContact';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-weight: 300;
}

body {
    font-family: 'Roboto', 'Source Sans Pro', sans-serif;
    font-weight: 300;
    color: $fontColor;
}

#root {
    display: flex;
}

.main-content {
    width: 100%;
    background-color: #f7f7f7;
    padding: 0 20px;
    min-height: 100vh;
    transition: all 0.3s;
}

.container h1 {
    font-size: 40px;
    transition-duration: 1s;
    transition-timing-function: ease-in-put;
    font-weight: 500;
}

h1 {
    font-weight: 700;
    margin: 1rem 0;
    padding-bottom: 4px;
    border-left: 20px solid $borderMenu;
    padding-left: 20px;
    &::first-letter {
        text-transform: uppercase;
    }
}

h1.message {
    border-bottom: 1px solid $mainColor;
    padding: 0.5rem 1rem;
}

.nav {
    a {
        color: #636e72;
    }
}

// Gestion du mobile
@media screen and (max-width: $breakpoint-mobile) {
    h1 {
        position: relative;
        padding-left: 55px;
        border: none;
        font-size: 1.5rem;
    }

    .wrapper-login {
        h1 {
            padding-left: 0;
        }
    }

    .main-content {
        min-width: 100%;
        margin-left: -10px; //UGLY...
        margin-top: 60px;
        padding: 0 5px;
        .container-fluid {
            padding: 0;

            .MuiToolbar-gutters,
            .MTableToolbar-searchField-38 {
                padding-left: 0;
            }
        }

        .sidebar {
            &.closed {
                margin-left: 0px;
                min-width: 0;
                .nav-fixed {
                    width: 0;
                }
            }
        }
    }
}
