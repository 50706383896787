@import './variables';




.widget-Demande {
    .card {
        --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        padding: 20px;
        margin-bottom: 10px;
        color: #343837;
        font-weight: 400;

        &.click {
            cursor: pointer;
            font-size: 20px;
            font-weight: 500;
            width: 100%;
        }
        &.click:hover {
            background-color: rgba(245, 12, 12, 0.1);
        }
        .image {
            height: 60px;
            width: 60px;
            margin: 0 auto;
            margin-bottom: 10px;
        }
    }
    .card:hover{
        cursor: pointer;
        background-color: rgba(245, 12, 12, 0.1);
    }
    .container-widget {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        .widget {
            width: 49%;
            height: 133px;
            .valeur {
                font-weight: bold;
                font-size: 40px;
                &.en-cours {
                    color: #1976d2;
                }
                &.en-attente {
                    color: #f39c12;
                }
            }
        }
    }
}

.widget-CAI {
    background-color: white;
    h3 {
        font-weight: bold;
    }
}

.widget-produit {
    background-color: white;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

    .produit {
        display: flex;
        flex-direction: row;
        height: 30px;

        p {
            font-weight: 550;
            font-size: 14px;
            padding: 10px;
        }
        .couleur-logiciel {
            width: 15%;
            height: 100%;

            &.OW {
                background-color: $omniweb;
            }

            &.OB {
                background-color: $homere;
            }
        }
    }
}

.catalogue{
    height: 133px;
    text-decoration: none !important;
    font-weight: 300 !important;
}


.lien-formation{
    height: 92px; 
}

.widget-formation{
    width: 100%;
}

.incremente{
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-weight: 200 !important; 
    color: #343837;
    background-color: white;
    height: 133px;
    margin-left: 5px;
    font-size: 40px;
    width: 10%;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.incremente:hover{
    cursor: pointer;
    background-color: rgba(245, 12, 12, 0.1);
}


.source_icone{
    font-size: 10px !important;
    position : fixed;
    bottom: 5px !important;
    right: 45% !important;
}



@media screen and (max-width: $breakpoint-mobile) {
    .source_icone{  
        position :inherit;
         text-align: center;
    }
}