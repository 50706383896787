.card-container {
    font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;

    .avatar {
        background-color: rgb(224, 221, 221);
        position: relative;
        //text-align: center;
        flex: 0 0 auto;
        height: 100px;
        width: 100px;
        margin: auto;
        top: -50px;
        left: 0px;
        right: 0px;
    }

    .card-info {
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        height: 600px;
        margin-left: 50px;
        position: relative;

        hr {
            width: 80%;
            margin: auto;
            margin-top: 20px;
        }

        .identifiant {
            text-align: center;
            .nom {
                font-size: 25px;
            }
            .societe {
                font-size: 18px;
            }
            .poste {
                color: gray;
                font-weight: 300;
            }
        }

        .info-plus {
            margin-top: 15px;
            margin-left: 20px;
            display: flex;
            flex-direction: column;

            .email,
            .numero {
                padding-top: 10px;
                font-size: 15px;
                float: right;
                padding-left: 10px;
            }
            .icon {
                float: left;
                color: #d32f2f;
                margin-right: 5px;
            }

            .titre-produit{
                font-weight: 500;
                font-size: 14px;
                margin-left: 20px;
            }
            .produit{
                padding-top: 5px;
                margin-left: 20px;
                .span-produit{
                    //text-transform: lowercase;
                    text-transform: capitalize;
                }
 
            }
        }

        .bottom-card {
            position: absolute;
            bottom: 0;
            width: 100%;

            hr {
                width: 80%;
                margin: auto;
                margin-top: 20px;
            }

            .deconnecte {
                margin-left: 40px;
                color: #d32f2f;
                text-decoration-color: #d32f2f;
                cursor: pointer;
            }
        }
    }

    .card-secodaire {
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        height: 290px;
        text-align: center;
        position: relative;

        &.mdp {
            margin-bottom: 20px;
        }

        .titre {
            padding-top: 30px;

            font-size: 25px;
        }

        img {
            text-align: center;
            height: 100px;
        }

        .container-form {
            display: flex;
            justify-content: center;
            margin: auto;
        }

        .champs {
            margin-top: 10px;
        }

        .description {
            margin: 20px;
        }

        .btn-displayForm {
            font-size: 18px;
            font-weight: 400;
            color: #d32f2f;
            text-decoration-color: #d32f2f;
            cursor: pointer;
        }
    }
}
