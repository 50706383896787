.mention-corporate {
    font-family: AuraRegular;
    position: absolute;
    bottom: 1%;
    width: 100%;
    text-align: center;

    .debug-info {
        color: $mainColor;
    }
}

@media screen and (max-width: $breakpoint-mobile) {
    .mention-corporate {
        bottom: 2%;
        font-size: 0.8rem;
    }
}
