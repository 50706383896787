@media print {
    * {
        overflow: visible !important;
        -webkit-transition: none !important;
        transition: none !important;
    }

    @page {
        size: A4 landscape;
        margin: 25mm 25mm 25mm 25mm !important;
    }

    body,
    #root,
    .main-content {
        background-color: white !important;
        margin: 0;
    }

    #root {
        background: transparent;
    }

    html,
    body {
        // page-break-before: avoid;
        width: 100%;
        height: 100%;
        overflow: visible;
        // -webkit-transform: rotate(-90deg) scale(0.68, 0.68);
        // -moz-transform: rotate(-90deg) scale(0.58, 0.58);
        // zoom: 80%;
    }

    .no-print,
    .sidebar,
    .MuiToolbar-root,
    .MuiTooltip-popper,
    .btn-close-drawer {
        display: none !important;
    }

    /* TABLE */
    .MuiTable-root {
        table-layout: fixed !important;
    }

    .page-break-before {
        break-before: always !important;
    }

    .page-break-after {
        break-after: always;
    }

    /* TEAM AGENDA */

    .main-content {
        min-height: 0vh !important;
    }

    .MuiBackdrop-root {
        background: transparent !important;
    }

    .MuiDrawer-modal {
        position: relative !important;

        .container-drawer {
            height: 100% !important;
        }

        .MuiDrawer-paper {
            position: relative !important;
        }
    }

    .MuiSvgIcon-root.controller-user {
        display: none;
    }

    .zui-table .zui-sticky-col.member {
        font-size: 0.6rem;
    }

    .zui-table .zui-sticky-col {
        // width: 138px;
        width: 0;
    }

    .zui-scroller {
        width: calc(100% - 130px);
        margin-left: 130px;
        overflow-x: hidden;
    }

    .MuiGrid-container.panel-widget-agenda {
        display: none;
    }

    // Optimisation calendrier annuel
    // .container-month {
    //     &.MuiGrid-item {
    //         max-width: 8.333333% !important;
    //         flex-basis: 8.333333% !important;
    //     }
    // }

    .MuiGrid-grid-md-3 {
        flex-grow: 0 !important;
        max-width: 25% !important;
        flex-basis: 25% !important;
    }

    .print-size-1 {
        max-width: 8.333333% !important;
        flex-basis: 8.333333% !important;
    }

    .print-size-2 {
        max-width: 16.666667%;
        flex-basis: 16.666667%;
    }

    .print-size-3 {
        max-width: 25% !important;
        flex-basis: 25% !important;
    }

    .print-size-9 {
        max-width: 75% !important;
        flex-basis: 75% !important;
    }

    /* Pointage reporting */

    .report-users-pointage {
        display: block !important; // !!!!!FIX break page : le parent de lélement où le saut de page est ajouté doit avoir un display:block et pas de display:flex

        h2 ul li {
            font-size: 12px !important;
        }
    }

    .report-users-pointage .pointage-header,
    .report-users-pointage .pointage-header .pointage-info ul.list-profiles {
        font-size: 14px !important;
    }

    div.user-container {
        break-after: always;
        display: block;
        overflow: hidden;
    }

    table.grid-user-pointage {
        // width: 100vmax;
        max-width: 3508px;
        width: 100%;
        // break-inside: avoid;
        font-size: 9px;
        display: block;

        td {
            width: 100px; // popur que la table soit à 100%
            overflow: hidden;
            word-wrap: break-word;
        }
    }

    table {
        page-break-inside: auto;
    }
    tr {
        page-break-inside: avoid;
        page-break-after: auto;
        width: 100%;
    }
    thead {
        display: table-header-group;
    }
    tfoot {
        display: table-footer-group;
    }
}
