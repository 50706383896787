body {
    .MuiButton-root:not(:last-of-type) {
        margin-right: 20px;
    }
    button.MuiIconButton-root {
        // width: 48px; //mui grid icon button

        &[title="Ajouter"],
        &[title="Add"] {
            // color: white;
            background-image: url("../../images/svg/addCircle.svg") !important;
            background-position: center center;
            background-size: 60%;
            background-repeat: no-repeat;
            text-indent: -999999px;

            &:hover {
                background-image: url("../../images/svg/addCircle.svg");
                background-position: center center;
                background-size: cover;
                text-indent: -999999px;
                font-size: 40rem;
            }

            .material-icons,
            .MuiIconButton-label {
                visibility: hidden;
                background: none !important;
            }
        }
    }
}

.MuiButton-root {
    &.btn-save {
        background-color: #00cec9;
        color: #fff;

        &:hover {
            background-color: #00b894;
        }
    }
}

.container-button-action {
    button {
        padding: 0px;
        &:not(:last-of-type) {
            margin-right: 6px;
        }
    }
}

.button-card {
    position: relative;
    min-height: 140px;
    width: 100%;
    // text-transform: capitalize;
    background-color: $mainColor;
    font-weight: 600;
    color: white;
    // padding-left: 40%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
        0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1);

    background-image: url("../../images/svg/user-boy-2.svg");
    background-repeat: no-repeat;
    background-size: 40px;
    // background-position: 10% center;
    transition: all 0.5 ease;

    padding-top: 72px;
    font-size: 1rem;
    background-position: 50% 25%;

    &.btn-valid {
        .MuiSvgIcon-root,
        .counter {
            color: $validColor;
        }
    }

    &.btn-error {
        .MuiSvgIcon-root,
        .counter {
            color: $errorColor;
        }
    }

    &.btn-plus {
        .MuiSvgIcon-root {
            color: $starColor;
        }
    }

    &.btn-inProgress {
        .MuiSvgIcon-root,
        .counter {
            color: $warningColor;
        }
    }

    &.btn-reliquat {
        .MuiSvgIcon-root,
        .counter {
            color: $mainColor;
        }
    }

    &.light {
        background-color: $white;
        color: $fontColor;
    }

    &:not(.disable):hover {
        background-size: 45px;
        background-color: $mainColorGradient;
        transform: scale(1.06);
        text-shadow: 1px 1px 2px $fontColor;
    }

    &.btn-users {
        background-image: url("../../images/svg/user.svg");
    }

    &.btn-modules-rights {
        background-image: url("../../images/svg/lock.svg");
    }

    &.btn-groups {
        background-image: url("../../images/svg/users.svg");
    }

    &.btn-types {
        background-image: url("../../images/svg/settings.svg");
    }

    &.btn-custom {
        background-image: url("../../images/svg/theme.svg");
    }

    &.btn-workflow {
        background-image: url("../../images/svg/workflow.svg");
    }

    &.btn-request {
        background-image: url("../../images/svg/request_add.svg");
    }

    &.btn-rights {
        background-image: url("../../images/svg/balance.svg");
    }

    &.btn-lock {
        background-image: url("../../images/svg/lock.svg");
    }
}

a.link-button {
    text-decoration: none;
}

.button-card-counter,
.button-card-delegation {
    background: none;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    h4 {
        text-align: left;
        font-size: 1rem;
    }
    .counter {
        font-size: 3.8rem;
        display: flex;
        justify-content: right;
        flex: 1;
        width: 100%;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        color: $mainColorGradient;
        font-family: "Digital";
    }

    .MuiSvgIcon-root {
        position: absolute;
        top: 2px;
        right: 2px;
        font-size: 1.6rem;
        width: 1.2em;
        height: 1.2em;
        color: $fontColor;
    }
}

h1 {
    .btn-nav-back {
        position: absolute;
        background-color: #b2bec3;
        right: 0;
    }
}

.button-card-timer {
    height: 100px;
    width: 100% !important;
    // padding-top: 30%;
    background-position: 50% 20%;
    padding-top: 62px;

    &.disable {
        cursor: not-allowed;
        opacity: 0.4;
    }

    &.btn-timer {
        background-image: url("../../images/svg/clock.svg");
    }

    &.btn-pause {
        background-size: 40px;
        background-image: url("../../images/svg/pause.svg");
        &:not(.disable):hover {
            background-size: 50px;
        }
    }

    &.btn-mission {
        background-image: url("../../images/svg/target.svg");
    }

    &.btn-lunch {
        background-image: url("../../images/svg/food.svg");
    }

    &.btn-question {
        background-color: #b2bec3;
        background-image: url("../../images/svg/question.svg");
    }
}

.button__validate {
    &.button__validate {
        background-color: $validColor;
        &:hover {
            background-color: #00b894;
        }
    }
}

.button-extra {
}

.btn-fullWidth {
    width: 100%;
}

.MuiButton-contained {
    &.button__history {
        background-color: #e84393;
        color: #fff;
        &:hover {
            background-color: #b53471;
        }
    }
}

a.button-link {
    text-decoration: none;
    color: inherit;
    &:hover {
        color: inherit;
    }
}

.menu {
    .MuiIconButton-root {
        flex: 0 0 auto !important;
        width: auto;
    }
}

button {
    .svg-btn {
        width: 25px;
        opacity: 0.54;
    }
    &.disable {
        .svg-btn {
            opacity: 0.1;
        }
    }
    &.btn-paste {
        color: #00cec9;
    }
    &.btn-cancel {
        color: $cancelColor;
    }
}

.MuiSvgIcon-root.icon-badge {
    position: absolute;
    width: 15px;
    bottom: -5px;
    right: -2px;
}

.MuiButtonBase-root.btn-header-right {
    position: absolute;
    right: 50px;
    top: 15px;
    .MuiSvgIcon-root {
        padding: 0;
        width: 30px;
        height: 30px;
    }
}

ul.list-delegation {
    margin-top: 15px;
    list-style-type: none;
    width: 100%;

    li {
        padding: 4px 0;
        font-size: 0.9rem;
        text-align: left;
        .delegant {
            display: flex;
            align-items: end;
            span {
                font-weight: 400;
            }
            .MuiSvgIcon-root {
                font-size: 0.9rem;
                position: relative;
            }
        }
        .period {
            font-size: 0.8rem;
            word-break: break-all;
        }
    }
}

@media screen and (max-width: $breakpoint-mobile) {
    .button-card {
        // background-size: 25%;

        // &:hover {
        //     background-size: 26%;
        // }
    }

    .button-card-timer {
        height: auto;
    }

    .btn-nav-back {
        width: 10%;
        top: -8px;
    }
}
