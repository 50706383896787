@import '_navicon';

.sidebar {
    position: relative;
    z-index: 100;
    height: 100%;

    .container-menu {
        max-height: calc(100vh - 220px);
        overflow-y: scroll;
        overflow-x: hidden;

        &.custom-scrollbar::-webkit-scrollbar {
            width: 0px;
            background-color: #f5f5f5;
            display: none;
        }

        &.custom-scrollbar {
            //FIREFOX
            scrollbar-width: none;
        }
    }

    .avatar {
        position: relative;
        margin-top: 10px;
        margin-bottom: 80px;
        color: $white;
        cursor: pointer;

        .picture {
            position: absolute;
            left: 5px;
            display: inline-block;
            background: $white;
            width: 60px;
            height: 60px;
            background-size: cover;
            border-radius: 50%;
            z-index: 2;
            transition: all 0.3s;
            border: 2px solid $white;

            .role {
                top: -10px;
                left: 14px;
                position: absolute;
                width: 30px;
                height: 30px;
                &.admin {
                    // background-color: $gold;
                    background-image: url('../../images/svg/crown_gold.svg');
                    background-size: 80%;
                    background-repeat: no-repeat;
                    background-position: top;
                }
                &.supervisor {
                    //background-color: $gold;
                    background-image: url('../../images/svg/crown_silver.svg');
                    background-size: 80%;
                    background-repeat: no-repeat;
                    background-position: top;
                }
                &.webmaster {
                    background-color: $eggBlue;
                    background-image: url('../../images/svg/iron-man.svg');
                }
                &.manager,
                &.RH,
                &.initiales {
                    background-color: $eggBlue;
                    background-image: url('../../images/svg/team.svg');
                    top: 38px;
                    left: 38px;
                    color: white;
                    width: 25px;
                    height: 25px;
                    font-size: 1.2rem;
                    text-align: center;
                    font-weight: 700;
                    border-radius: 50%;
                    border: 1px solid #fff;
                    line-height: initial;
                }
                &.RH {
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 1rem;
                }

                &.initiales {
                    display: none;
                    top: 10px;
                    left: -6px;
                    width: 20px;
                    font-size: 1rem;
                    border-radius: 10px;
                    height: 40px;
                    background-color: $selectedColor;
                }
            }
        }
        div.fullname {
            position: absolute;
            z-index: 1;
            left: 60px;
            top: 4px;
            background: $mainColorGradient;
            width: 200px;
            padding: 4px 40px 2px 18px;
            margin-left: -10px;
            border: 1px solid $borderMenu;
            border-right: none;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            // text-shadow: 2px 2px 1px #000;

            span {
                display: block;
                font-weight: 700;
                font-size: 0.9rem;
            }

            .firstname {
                text-transform: capitalize;
                &::first-letter {
                    text-transform: uppercase;
                }
            }

            .lastname {
                text-transform: uppercase;
            }
        }

        div.pointage-status {
            display: flex;
            justify-content: flex-end;
            flex-direction: row;
            position: absolute;
            z-index: 1;
            right: 0;
            bottom: -76px;
            height: 30px;
            width: 100px;
            background-color: rgba(0, 0, 0, 0.3);
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
    }

    .nav-fixed {
        transition: all 0.3s;
        position: fixed;
        width: 250px;
        background: linear-gradient(to bottom right, $mainColorGradient 0%, $mainColor 100%);
        min-height: 100vh;
        overflow: hidden;
        height: 100%;

        .debug-info,
        .logo-info {
            position: absolute;
            bottom: 0px;
            right: 0px;
            color: $white;
            top: auto;

            .square {
                background-color: $white;
            }
        }

        .debug-info {
            right: 10px;
            bottom: 50px;
        }
    }

    &.closed {
        #nav-icon {
            right: 8px;
        }

        .container-menu {
            margin-top: 135px;
        }

        .avatar {
            .picture {
                top: 58px;
                left: 188px;
                // width: 45px;
                // height: 45px;
                transform: scale(0.7);
            }
            .fullname {
                display: none;
            }
        }

        .header-content {
            a:active {
                color: red;
                font-size: 25px;
            }

            li.item {
                background-position: 210px;
                height: 50px;
                max-height: 50px;

                span.label {
                    visibility: hidden;
                    text-align: right;
                    padding-left: 4px;
                }
            }
        }
    }

    padding: 0 10px 0 0;
    border-right: 1px solid #000;
    //text-transform: capitalize;
    min-width: 250px;
    max-width: 250px;
    transition: all 0.3s;

    &.closed {
        margin-left: -190px;

        .bg {
            left: -100px;
        }

        .logo {
            opacity: 0;
        }
    }

    .logo {
        transition: 0.5s opacity ease;
        position: fixed;
        bottom: 5px;
        margin-left: 90px;
        width: 150px;
        z-index: 1;
        display: none;

        img {
            -webkit-filter: drop-shadow(1px 1px 1px #fff);
            filter: drop-shadow(1px 1px 1px #fff);
        }
    }

    .bg {
        transition: all 0.3s ease;
        position: fixed;
        overflow: hidden;
        bottom: -75px;
        left: -5px;
        width: 180px;
        z-index: 0;
        opacity: 0.2;
        filter: sepia(1);
        img {
            margin-left: -10px;
            width: 100%;
        }
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            transition: all 0.5s ease;
            padding: 8px 10px;
            border-bottom: 1px solid $borderMenu;
        }
    }

    li.header {
        position: relative;
        background-color: $borderMenu;
        cursor: pointer;
        font-weight: bold;
        color: $white;
        white-space: nowrap;

        &::first-letter {
            text-transform: uppercase;
        }

        span.legend-small {
            display: none;
            float: right;
            font-size: 0.54rem;
            padding-top: 7px;
            padding-right: 14px;
            text-transform: capitalize;
            width: 45px;
        }

        i.material-icons {
            text-indent: -999px;
            transition: transform 0.8s ease-in-out;
            position: absolute;
            top: 18px;
            right: 10px;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid $white;
            transition: transform 0.3s ease-in-out;
        }

        &.expanded {
            i.material-icons {
                transform: rotate(180deg);
            }
        }
    }

    .header-content {
        //display: none;
        transition: max-height 1s;
        overflow: hidden;
        max-height: 0;

        &.expanded {
            //display: block;
            transition: max-height 1s;
            // max-height: 200px;
            max-height: initial;
        }
        a {
            color: $white;
            text-decoration: none;

            &[aria-current] li.item,
            li.item:hover {
                border: none;
                background-color: $selectedColor;
                // margin-left: 0;
            }
        }

        li.item {
            background: url('../../images/svg/iron-man.svg') no-repeat;
            background-position: 12px;
            background-size: 25px;
            font-size: 1rem;
            // margin-bottom: 4px;
            margin-left: -5px;
            padding: 14px 6px 14px 50px;
            border: none;
            position: relative;
            background-color: transparent;

            &::first-letter {
                text-transform: uppercase;
            }

            &.icon-plus::after {
                content: '+';
            }

            &.icon-team {
                background-image: url('../../images/svg/team.svg');
            }

            &.icon-squad {
                background-image: url('../../images/svg/squad.svg');
            }

            &.icon-mindmap {
                background-image: url('../../images/svg/mindmap.svg');
            }

            &.icon-application {
                background-image: url('../../images/svg/application.svg');
            }

            &.icon-rule {
                background-image: url('../../images/svg/business_rule.svg');
            }

            &.icon-users {
                background-image: url('../../images/svg/users.svg');
            }

            &.icon-settings {
                background-image: url('../../images/svg/settings.svg');
            }

            &.icon-license {
                background-image: url('../../images/svg/license.svg');
            }

            &.icon-gantt {
                background-image: url('../../images/svg/gantt.svg');
            }

            &.icon-dashboard {
                background-image: url('../../images/svg/dashboard.svg');
            }

            &.icon-calendar {
                background-image: url('../../images/svg/calendar.svg');
            }

            &.icon-calendar-annual {
                background-image: url('../../images/svg/columns.svg');
            }

            &.icon-request {
                background-image: url('../../images/svg/chat-add.svg');
            }

            &.icon-request-attente {
                background-image: url('../../images/svg/timeClock.svg');
            }

            &.icon-request-history {
                background-image: url('../../images/svg/mail-attente2.svg');
            }

            &.icon-video {
                background-image: url('../../images/png/video.png');
            }

            &.icon-request-validate {
                background-image: url('../../images/svg/validate.svg');
            }

            &.icon-request-balance {
                background-image: url('../../images/svg/balance2.svg');
            }

            &.icon-home-scepia {
                background-image: url('../../images/svg/home.svg');
            }

            &.icon-pointage-set {
                background-image: url('../../images/svg/timeline.svg');
            }

            &.icon-view {
                background-image: url('../../images/svg/see.svg');
            }

            &.icon-team-pointage {
                background-image: url('../../images/svg/group.svg');
            }

            &.icon-calculator {
                background-image: url('../../images/svg/calculator.svg');
            }

            &.icon-control {
                background-image: url('../../images/svg/checkmark.svg');
            }

            &.icon-calcultate {
                background-image: url('../../images/svg/calculator.svg');
            }

            &.icon-pay {
                background-image: url('../../images/svg/pay-day.svg');
            }

            &.icon-download {
                background-image: url('../../images/svg/download.svg');
            }

            &.icon-time-set {
                background-image: url('../../images/svg/chrono.svg');
            }

            &.icon-import {
                background-image: url('../../images/svg/import.svg');
            }

            &.icon-export {
                background-image: url('../../images/svg/export.svg');
            }
            &.icon-mail {
                background-image: url('../../images/svg/request.svg');
            }

            &.icon-logout {
                background-image: url('../../images/svg/logout.svg');
                margin-top: 40px;

                // &:hover {
                //     color: $eyeNewt;
                // }

                &:before {
                    content: '';
                    position: absolute;
                    top: -10px;
                    left: 20px;
                    display: block;
                    height: 1px;
                    width: 230px;
                    border-top: 1px solid $borderMenu;
                }
            }
        }
    }
}

.sidebar.closed {
    li.header {
        span.legend-small {
            display: inline-block;
        }
    }

    .header-content {
        a {
            &[aria-current] li.item {
                background-color: $selectedColor;
                border: none;
            }
            li.item:hover {
                border: none;
                // margin-left: 0;
                background-color: $selectedMenu;
                color: $mainColor;
            }
        }
    }
    .initiales {
        display: block !important;
    }
}

ul.avatar-subMenu {
    padding: 0;
    margin: 0;

    li {
        list-style-type: none;
        padding: 6px 14px;

        &.header {
            border-bottom: 1px solid #dfe6e9;
            font-weight: 500;
        }

        &.link {
            cursor: pointer;
            padding-left: 35px;

            .MuiSvgIcon-root {
                position: absolute;
                left: 5px;
            }

            &:hover {
                background-color: $mainColor;
                color: #fff;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-mobile) {
    #root {
        .main-content {
            margin-left: -10px; //UGLY...
            margin-top: 60px;
            padding: 0 5px;
            .container-fluid {
                padding: 0;

                .MuiToolbar-gutters,
                .MTableToolbar-searchField-38 {
                    padding-left: 0;
                }
            }
        }
        .sidebar {
            &.closed {
                margin-left: 0px;
                min-width: 0;
                .nav-fixed {
                    width: 0;
                }
            }
        }
    }
}
