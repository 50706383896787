#nav-icon {
    position: absolute;
    z-index: 2;
    top: 15px;
    right: 0px;
    width: 45px;
    height: 45px;
    -webkit-transform: rotate(0deg) scale(0.6);
    -moz-transform: rotate(0deg) scale(0.6);
    -o-transform: rotate(0deg) scale(0.6);
    transform: rotate(0deg) scale(0.6);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

#nav-icon span {
    display: block;
    position: absolute;
    height: 7px;
    width: 100%;
    background: $white;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
}

.sidebar.closed {
    #nav-icon span:nth-child(1) {
        top: 0px;
    }

    #nav-icon span:nth-child(2) {
        top: 16px;
    }

    #nav-icon span:nth-child(3) {
        top: 32px;
    }
}

.sidebar:not(.closed) {
    #nav-icon span:nth-child(1) {
        top: 8px;
        width: 50%;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    #nav-icon span:nth-child(2) {
        top: 16px;
    }

    #nav-icon span:nth-child(3) {
        top: 25px;
        width: 50%;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
    }
}

/* Pour le mobile on affiche un autre hamburger menu afin d'optimiser la taille d'affichage du main-content*/

.nav-mobile-top {
    top: 0px;
    position: fixed;
    background: rgba(255, 255, 255, 0.9);
    height: 50px;
    width: 100%;
}

#nav-icon-mobile {
    cursor: pointer;
    position: absolute;
    top: 12px;
    left: 15px;
    width: 40px;
    height: 40px;
    span {
        display: block;
        margin-bottom: 5px;
        width: 100%;
        height: 7px;
        background: $borderMenu;
        width: 100%;
        border-radius: 9px;
    }
}

@media screen and (min-width: $breakpoint-mobile) {
    .nav-mobile-top {
        display: none;
    }
}
