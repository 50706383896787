.MuiBottomNavigation-root .HERMESS_navigation {
    background-color: #f7f7f7;
}

.HERMESS_mainFile {
    .filepond--drop-label {
        background-color: #b2bec3;
        border-radius: 5px;

        label {
            //background-image: url("./../../images/svg/document.svg");
            background-repeat: no-repeat;
            background-size: 30px;
            background-position-y: center;
            padding-left: 35px;
        }
    }
}

.HERMESS_conversClient {
    background-color: #dff9fb;
    border: 1px solid #dff9fb;
}

.HERMESS_conversEmploye {
    background-color: #f1f2f6;
    border: 1px solid #f1f2f6;
}

.HERMESS_conversClientMail{
    opacity: 0.5;
}

.HERMESS_conversClient,
.HERMESS_conversEmploye {
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1);

    .HERMESS_conversClient_title {
        .HERMESS_conversClient_titleName {
            font-weight: bold;
        }
    }

    .HERMESS_conversClient_comment {
        text-align: justify;
        font-size: 1rem;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.00938em;
    }
}

.HERMESS_CardDemandes {
    //notif
    .HERMESS_NotifIcon {
        color: #e63946;
    }
    height: 220px;
    .echange {
        height: 40px;
    }
    &:hover {
        background-color: rgba(245, 12, 12, 0.1);
        .HERMESS_NotifIcon {
            animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            perspective: 1000px;
        }
    }
}

.MuiGrid-container {
    &.ticket {
        max-width: 1000px;
        margin: auto;
        margin-top: 10px;
        cursor: pointer;
    }

    .bottomCard {
        display: flex;
        justify-content: space-between;

        .cloture {
            margin: 10px;
            justify-content: start;
        }
        .item-voirPlus {
            justify-content: end;
        }
    }
}

.link {
    cursor: pointer;
}

.Accordion {
    margin-bottom: 15px;
}

.typo-accordeon {
    font-size: 18px !important;
    font-weight: 700 !important;
    color: #457b9d;
    display: flex;

    .logo {
        margin-right: 5px;
        color: #e63946;
    }
}

.span-info {
    color: #457b9d;
    font-weight: 700 !important;
}

.file {
    display: flex;
    flex-direction: row;
    margin-left: 10px;

    .typo {
        padding-right: 10px;
        font-size: 15px;
        font-weight: 700;
    }
}

.pagination {
    margin-top: 20px !important;
    display: flex;
    justify-content: center;
}

.setcion-recherche {
    display: flex;
    justify-content: space-between;
}

.div-recherche {
    display: flex;
    justify-content: flex-end;

    button {
        justify-items: center;
        height: 50px;
    }
}

.container-demandes {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fixe {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
}

.titre-h1 {
    display: flex;
    align-items: center;
}

.back-icon {
    margin-right: 10px;

    cursor: pointer;
    //font-weight: 500;
    color: #457b9d;

    font-weight: 700;
}

.back-icon:hover {
    text-decoration: underline;
}

.voirPlus {
    font-size: 0.875rem !important;
    margin-right: 5px !important;
}

#blanc-fin {
    height: 80px;
    width: 100%;
}
/**************************************************************** DEMANDES EN ATTENTES ************************************/

.mail-objet h3 {
    font-weight: 700;
    font-size: 20px;
    color: #457b9d;
    margin-bottom: 10px;
}

.jnGCJA .mail-expedition {
    margin-left: 10px;
    margin-bottom: 15px;
    .date {
        font-size: 15px;
        color: grey;
    }

    .expediteur {
        margin-bottom: 5px;
        font-size: 17px;
        font-weight: 500;
    }
}

#mail-destinataires  {
    margin-left: 10px;
    margin-bottom: 10px;

    span {
        border: 1px solid #457b9d;
        margin-right: 5px;
        border-radius: 50px;
        padding: 10px;
        font-weight: 500;
        font-size: 13px;
        background-color: #cce5f5b4;
        display: inline-table;
        margin-bottom: 5px;
    }
}

 #mail-corps {
    margin: 10px;
    border: 1px solid #457b9d;
    background-color: white;
    border-radius: 10px;
    p {
        margin: 10px;
        white-space: pre-line;
        white-space: pre-wrap;
        text-align: justify;
    }
}
