.logo-company {
    color: $logoColor;
    margin-left: -30px;
    font-family: AuraRegular;
    line-height: 34px;
}

.logo-company {
    // font-family: AuraRegular;
    margin-top: 0px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px 40px 0px 0px;
    width: fit-content;
    font-size: 2rem;
    transform: scaleY(0.8);
    .square {
        transition: all ease 2s;
        position: absolute;
        border-radius: 2px;
        top: 7px;
        right: 20px;
        background-color: $logoColor;
        width: 16px;
        height: 18px;

        &:nth-of-type(1) {
            right: 39px;
        }

        &:nth-of-type(3) {
            top: 28px;
        }
    }
    &.logo-scepia:hover {
        .square {
            transform: rotate(360deg);
        }
    }
}
