.fullpage-wrapper {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    img {
        width: max-content;
        text-align: center;
    }
}

body.maskLoader {
    filter: blur(3px);
    -webkit-filter: blur(3px);
}

.fullLoader {
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: 1000000000000;

    .maskLoader {
        height: inherit;
        width: inherit;
        background-color: rgba(178, 190, 195, 0.1);
    }

    img {
        width: 100px;
        position: fixed;
        top: 0;
        right: 0;
    }
}

@media screen and (min-width: $breakpoint-desktop) {
    .fullLoader {
        img {
            top: inherit;
            bottom: 0;
        }
    }
}
