$breakpoint-mobile: 576px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 992px;
$breakpoint-largeScreen: 1900px;

// STATUS color
$cancelColor: #ff7675;
$errorColor: #ff7675;
$validColor: #55efc4;
$warningColor: #f39c12;
$waitColor: #fdcb6e;
$starColor: #fdcb6e;
$commentColor: #0984e3;
$neutralColor: #b2bec3;
$resultColor: #b7f8e6;
$holidayColor: #fdcb6e;

$white: #fff;
$logoColor: #e6312d;
$fontColor: #343837;
$mainColor: #e6312d;
$mainColorGradient: #db8484;
$link-color: #e6312d;
$gold: #f39c12;
$borderMenu: #2d3436;
$eggBlue: #00cec9;
$eyeNewt: #82ccdd;
$selectedMenu: #b2bec3; //#c0cace
$scepiaColor: #e6312d;
$selectedColor: #636e72;
$hoverColor: #f5f6fa;

// Couleur Logicel
$homere: #f39c12;
$nexthome: #ec5c59;
$leon: #0984e3;
$ph: #db8484;
$hteem: #00cec9;
$omniweb: brown;
$newWeb: #82ccdd;
$hermess: #e6312d;
