.news {
    box-shadow: 0 4px 4px #0005, 0 4px 4px #0004;
    width: 60%;
    margin: auto;
    margin-bottom: 40px;
    // border-radius: 10px;
    overflow: hidden;

    h2 {
        padding: 10px;
        text-align: center;
    }

    .image {
        background-color: beige;
        background-size: cover;
        width: 100%;
        height: 500px;
        margin-bottom: 20px;
    }

    .description {
        padding: 20px 40px;
    }
    .info {
        border-top: 1px solid rgb(238, 232, 232);
        padding: 10px;
        display: flex;
        justify-content: flex-end;
        font-size: 0.9rem;
    }

    .highlight {
        font-weight: 500;
        margin: 0 4px;
    }
}
