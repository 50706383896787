.wrapper-login {
    background: #50a3a2;
    color: white;
    background: linear-gradient(to bottom right, $mainColorGradient 0%, $mainColor 100%);
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 400px;
    margin-top: -200px;
    overflow: hidden;

    .MuiFormLabel-root {
        background-color: rgba(255, 255, 255, 0);
        // padding: 4px 10px;
    }

    input,
    label {
        color: white;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
    }

    .btn-pwd-visibility {
        width: auto !important;
    }

    .container {
        position: relative;
        z-index: 2;
        max-width: 700px;
        margin: 0 auto;
        padding: 20px 0 80px;
        height: 500px;
        text-align: center;

        h1 {
            font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
            font-size: 2rem;
            border: none;
            padding: 0;
        }

        .link-forgetPassword,
        .link-login {
            a,
            &:active {
                color: #fff;
            }
        }

        .link-login {
            margin-top: 30px;
        }
    }
}


    .mdpOublie {
        color: #ffffff !important;
        cursor: pointer;
    }


@media screen and (max-width: $breakpoint-mobile) {
    .wrapper-login {
        top: 45%;
        height: 70vh;
        padding: 10px;
        .container {
            width: 100%;
            padding: 20px 0 80px;
        }
    }
}
